<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Создание страницы</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Новая страница</h5>
        </div>
      </div>
      <div class="card-body">
        <a-form-model ref="form" :model="form">
          <a-form-model-item class="text_area" label="Статус" required>
            <a-select v-model="form.active">
              <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Алиас страницы" required>
            <a-input v-model="form.alias"/>
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Заголовок(ua)" required>
            <a-input v-model="form.ua.name"/>
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Заголовок(ru)" required>
            <a-input v-model="form.ru.name"/>
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Контент(ua)" required>
            <quill-editor ref="quillEditor"  v-model="form.ua.description"></quill-editor>
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Контент(ru)" required>
            <quill-editor ref="quillEditor" v-model="form.ru.description"></quill-editor>
          </a-form-model-item>
        </a-form-model>
        <a-button key="submit" type="primary" @click="submit" :disabled="isDisabled">
          {{ $route.params.id !== 'new' ? 'Сохранить изменения' : 'Создать'}}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'Page',
  data() {
    return {
      form: {
        active: '',
        alias: '',
        ru: {
          name: '',
          description: '',
        },
        ua: {
          name: '',
          description: '',
        },
      },
      statuses: [
        { name: 'Активная', id: 1 },
        { name: 'Не активная', id: 0 },
      ],
    }
  },
  computed: {
    isDisabled() {
      const form = this.form
      return !(`${form.active}` && form.alias && form.ru.name && form.ru.description && form.ua.description && form.ua.description)
    },
  },
  async created() {
    if (this.$route.params.id !== 'new') {
      const page = (await this.$services.get(`admin/content_pages/${this.$route.params.id}`)).data.data.value
      const ua = page.translations.find((item) => item.locale === 'ua')
      const ru = page.translations.find((item) => item.locale === 'ru')
      this.form = {
        active: page.active,
        alias: page.alias,
        ru: {
          name: ru.name,
          description: ru.description,
        },
        ua: {
          name: ua.name,
          description: ua.description,
        },
      }
    }
  },
  methods: {
    async submit() {
      try {
        this.$route.params.id !== 'new' ? await this.$services.put(`admin/content_pages/${this.$route.params.id}`, this.form) : await this.$services.post('admin/content_pages', this.form)
        this.$router.back()
      } catch (e) {
        console.log(e)
      }
    },
  },
  components: { quillEditor },
}
</script>

<style scoped lang="scss">
button {
  margin-left: auto;
  display: block !important;
}
</style>
